import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
// import { SwUpdate } from '@angular/service-worker';
import * as Debug from 'debug';
import * as moment from 'moment-timezone';
import 'moment/min/locales'; // minified all locales
import { environment } from '../environments/environment';

const debug = Debug('sst:app-component');

@Component({
  host: {
    class: 'mat-app-background',
  },
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit, OnDestroy {
  public isReview = environment.appName === 'review';

  public constructor(
    private renderer: Renderer2,
    // private swUpdate: SwUpdate,
  ) {
    const appName = environment.appName === 'review-training'? 'review': environment.appName;
    this.renderer.addClass(document.body, appName);
    moment.relativeTimeThreshold('s', 59);
    moment.relativeTimeThreshold('ss', 0);
    // set the locale to OS setting.  we might enable this setting in config screen?
    moment.locale(window.navigator.language);
    // debug(moment.months());
  }

  public ngOnDestroy() {
    debug('ngOnDestroy');
    this.renderer.removeClass(document.body, environment.appName);
  }

  public ngOnInit() {
    if (window.parent !== window) {
      // i'm running in an iframe
      // we don't support regular iframe use
      // iframe use is restricted to the authService's needs to silently login
      debug('iFrame ngOnInit');
      return;
    }
    debug('ngOnInit');
    debug('environment', environment);
  }

}
