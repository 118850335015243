
export const appSupportedBrowsersName = 'Review';
export const appSupportedBrowsers = [
  { name: 'Chrome', version: 81 },
  // { name: 'Firefox', version: 45 },
  // { name: 'Safari', version: 9.0 },
  // { name: 'MSIE', version: 11 },
  // { name: 'Edge', version: 14 },
  // { name: 'Opera', version: 35 },
];
