/* tslint:disable-next-line:no-var-requires */
import build from '../build.json';

const domain = window.location.hostname.split('.').slice(-2).join('.');

const oauth2 = {
  'review': {
    clientId: 'soundthinking.review.fc7aac260c0eada4',
    logoutRedirectUri: `https://${window.location.hostname}/logout-redirect`,
    openIdConnectUrl: `https://hydra.stg.${domain}`,
    redirectUri: `https://${window.location.hostname}/login-redirect`,
  },
  'review-training': {
    clientId: 'soundthinking.review-training.dbb812865ea4b506',
    logoutRedirectUri: `https://${window.location.hostname}/logout-redirect`,
    openIdConnectUrl: `https://hydra.stg.${domain}`,
    redirectUri: `https://${window.location.hostname}/login-redirect`,
  },
};

const svcHost = `svc.stg.${domain}`;
const dashboardHost = `dashboard.stg.${domain}`;

export const environment = {
  appName: build.appName,
  authUrlWhitelist: [
    `https://${svcHost}`,
    `https://${dashboardHost}`,
  ],
  baseUrl: {
    areaService: `https://${svcHost}/api/area`,
    authService: `https://${svcHost}/api/auth`,
    geoDataService: `https://${svcHost}/api/geodata`,
    imsService: `https://${svcHost}/api/ims`,
    messageService: `https://${svcHost}/api/message`,
    presenceService: `https://${svcHost}/api/presence`,
    reportService: `https://${svcHost}/api/reports`,
    reverseGeocode: `https://${svcHost}/api/reverse-geocode`,
    reviewService: `https://${svcHost}/api/review`,
    ruleService: `https://${svcHost}/api/rule`,
    searchService: `https://${dashboardHost}/api`,
    sensorService: `https://${svcHost}/api/sensor`,
    settingsService: `https://${svcHost}/api/setting/v1`,
    socketService: {
      host: `https://${svcHost}`,
      path: '/api/socket-v2/ws',
    },
    trainingService: `https://${svcHost}/api/training/v1`,
    userService: `https://${svcHost}/api/user`,
  },
  build,
  electronWrapperVersion: undefined,
  googleAPIKey: 'AIzaSyAzAGuD-QnI2uQW2d9ikV-uMzd9I3g8IPo',
  name: 'stg',
  oauth2: oauth2[build.appName],
  production: true,
};

if (build.appName === 'review-training') {
  environment.baseUrl.reviewService = `https://${svcHost}/api/training/v1/review`;
  environment.baseUrl.imsService = `https://${svcHost}/api/training/v1/ims`;
}
