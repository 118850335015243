import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, EventEmitter } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AUTH_CONFIG, AuthModule } from '@sst-inc/angular-auth';
import { SOCKET_CONFIG, SocketModule } from '@sst-inc/angular-socket';
import { HotkeyModule } from 'angular2-hotkeys';
import Debug from 'debug';
import 'hammerjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { SentryErrorHandler } from './sentry';
import { DelayInterceptor } from './shared/delayInterceptor';
import { Browser } from './supported-browsers/browserInfo';
import { BrowserGuard } from './supported-browsers/guard.service';
import './theme';
const debug = Debug('sst:app.module');
// test for electron environment
// need to update environment before use of the provider useValue below
if (window['require']) {
    environment.build.electronWrapperVersion = new URLSearchParams(window.location.search).get('electronWrapperVersion');
    // override the platform
    environment.build.platform = 'electron';
}
const receivedCustomURL = new EventEmitter();
export function getReceivedCustomURL() {
    return receivedCustomURL;
}
// Handler for custom URL scheme (com.shotspotter.alerts://)
window.handleOpenURL = (url) => {
    receivedCustomURL.emit(url);
};
export function closeBrowserTab() {
    debug('should close sso browser tab');
    // defer until we implement cordova.
    // SafariViewController.hide();
}
const ɵ0 = () => {
    return receivedCustomURL;
};
const authConfig = Object.assign({}, {
    build: environment.build,
    appName: environment.appName,
    oauth2: environment.oauth2,
    getReceivedCustomURL: ɵ0,
    closeBrowserTab,
    urlWhitelist: environment.authUrlWhitelist,
    useRefreshToken: true,
    persistentRefreshToken: true,
    userMeEndpoint: environment.baseUrl.userService + '/v1/me',
});
const socketConfig = {
    build: environment.build,
    host: environment.baseUrl.socketService.host,
    path: environment.baseUrl.socketService.path,
    appName: environment.appName,
};
const ɵ1 = authConfig, ɵ2 = socketConfig;
const providers = [
    { provide: AUTH_CONFIG, useValue: ɵ1 },
    { provide: SOCKET_CONFIG, useValue: ɵ2 },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    Browser,
    BrowserGuard,
];
if (environment.name !== 'production') {
    providers.push({ provide: 'env', useValue: environment });
    providers.push({
        multi: true,
        provide: HTTP_INTERCEPTORS,
        useClass: DelayInterceptor,
    });
}
const imports = [
    // ServiceWorkerModule.register('./ngsw-worker.js', { enabled: ((environment.name !== 'dev') || (!!localStorage.useSW)) }),
    AuthModule.forRoot(authConfig),
    SocketModule.forRoot(socketConfig),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    HotkeyModule.forRoot(),
];
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2 };
