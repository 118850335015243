import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LoginComponent } from '@sst-inc/angular-auth';
import { BrowserGuard } from './supported-browsers/guard.service';
import { SupportedBrowsersComponent } from './supported-browsers/supported-browsers.component';

const routes: Routes = [
  {
    component: SupportedBrowsersComponent,
    path: 'supported-browsers',
  },

  // Auth routes
  {
    component: LoginComponent,
    path: 'login-redirect',
  },
  {
    component: LoginComponent,
    path: 'logout-redirect',
  },
  {
    component: LoginComponent,
    path: 'silent-refresh',
  },

  {
    canLoad: [BrowserGuard, AuthGuard],
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
    path: '',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule { }
