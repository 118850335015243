import { Routes } from '@angular/router';
import { AuthGuard, LoginComponent } from '@sst-inc/angular-auth';
import { BrowserGuard } from './supported-browsers/guard.service';
import { SupportedBrowsersComponent } from './supported-browsers/supported-browsers.component';
const ɵ0 = () => import("./main/main.module.ngfactory").then((m) => m.MainModuleNgFactory);
const routes = [
    {
        component: SupportedBrowsersComponent,
        path: 'supported-browsers',
    },
    // Auth routes
    {
        component: LoginComponent,
        path: 'login-redirect',
    },
    {
        component: LoginComponent,
        path: 'logout-redirect',
    },
    {
        component: LoginComponent,
        path: 'silent-refresh',
    },
    {
        canLoad: [BrowserGuard, AuthGuard],
        loadChildren: ɵ0,
        path: '',
    },
];
export class AppRoutingModule {
}
export { ɵ0 };
