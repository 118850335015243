import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';

if (environment.name !== 'dev') {
  Sentry.init({
    dsn: 'https://3da86f1e0abf4b008ad129770af6a3ab@sentry.io/1421839',
    environment: environment.name,
    release: environment.build.appName + '@' + environment.build.version,
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  public handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}
