import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Browser } from './browserInfo';

@Component({
  selector: 'app-supported-browsers',
  styleUrls: ['./supported-browsers.component.scss'],
  templateUrl: './supported-browsers.html',
})
export class SupportedBrowsersComponent {

  constructor(
    private titleService: Title,
    public browser: Browser,
  ) {
    // TODO: copy appname from environment.appname
    //       import it
    this.titleService.setTitle(this.browser.appName + ' - supported browsers');

  }
}
