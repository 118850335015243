<div class="supported-browsers" >
    <div class="title text-center" >Supported web browsers</div>
    <div >
        <p>To maintain security, it is important to be using a recent version of a modern browser.</p>
        <p *ngIf="!browser.isValid" >You are using an unsupported web browser.</p>
        <p>You are using {{browser.info.name}} {{browser.info.version}}.</p>
        <p>These are the minimum versions of popular browsers supported:</p>
        <ul>
            <li *ngFor="let browser of browser.supportedBrowsers">{{browser.name}} {{browser.version}}</li>
        </ul>
        <p>Internet Explorer or Safari on Windows is NOT supported</p>
        <p>To install a supported browser or to update your existing browser, contact your IT team or visit <a href="https://updatemybrowser.org/">updatemybrowser.org</a>.</p>

    </div>
</div>
