import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, from, Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import * as uuid from 'uuid';
import * as Debug from 'debug';
import { environment } from '../../environments/environment';

const debug = Debug('sst:delayInterceptor');

@Injectable()
export class DelayInterceptor implements HttpInterceptor {

  constructor(
    @Inject('env') private env,
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const min = +localStorage.minDelay || 0;
    let max = +localStorage.maxDelay || 0;
    if (!(min || max)){
      return next.handle(req);
    }
    if (max < min) {
      debug('maxDelay < minDelay - setting max = min')
      max = min;
    }

    const range = max - min;
    
    const offset = Math.round(range * Math.random());
    const delayMs = min + offset

    const delay = timer(delayMs);

    debug(`delaying http request by ${delayMs}`);

    return delay.pipe(switchMap(() => next.handle(req)));
    
  }

}
