import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// auth module is not aware of base-href, so we need to remove /v1 from the
// auth.url where the module is navigating to after authentication
const authUrl = window.sessionStorage.getItem('auth.url');
if (typeof authUrl === 'string') {
  window.sessionStorage.setItem('auth.url', authUrl.replace(/^\/v1/, ''));
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(console.error);
